import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { PrivacyPageTemplate } from '../templates/privacy-template'

const PrivacyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <PrivacyPageTemplate
      privacyText = { frontmatter.privacyText }
    />
  )
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default PrivacyPage

export const privacyPageQuery = graphql`
query PrivacyTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "privacy-page"}}) {
    frontmatter {
      
      privacyText      
    }
  }
}
`