import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import PrivacyText from '../components/PrivacyText'
import Layout from '../components/Layout'
import Spacer from '../components/Spacer'

export const PrivacyPageTemplate = ({
  privacyText,
}) => (
    <div>
      <Helmet title="Datenschutz" defer={false}/>
      <Layout>
        <Spacer height={60} id="top"/>
        <PrivacyText data = { privacyText }/>
        <Spacer height={30}/>
      </Layout>
    </div>
)

PrivacyPageTemplate.propTypes = {
  privacyText: PropTypes.object,
}