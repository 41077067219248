import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'


const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:0 auto;

& * {
  font-family:Lato;
}

#textContainer {

  font-size:16px;
}
`

const PrivacyText = ({data}) => (
  <Container>
    <ReactMarkdown id='textContainer'>{ data }</ReactMarkdown>
  </Container>
)

PrivacyText.propTypes = {
  privacyText: PropTypes.string,
}


export default PrivacyText